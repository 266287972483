import React, { useState } from 'react'
import * as b from './bootstrap.module.css'
import Icon from "../components/icon.tsx"
import Translate from './translate'

const Grid = ({ children }) => {
  const [grid, setGrid] = useState('column')

  const updateGrid = (value) => () => {
    setGrid(value);
  };

  return (
    <>
      <div className={['grid', b.dNone, b.dLgFlex, b.justifyContentLgEnd, b.alignItemsCenter, b.mb3].join(' ')}>
        <span className={['grid-text', b.mr3].join(' ')}><Translate tKey="Change view" /></span>

        <button onClick={updateGrid('row')} className={['settings-button', grid === 'row' ? 'settings-button-active' : '', b.mr2].join(' ')}>
          <Icon  size={25} icon="Columne-list" className={"icon-padding"}/> 
        </button>

        <button onClick={updateGrid('column')} className={['settings-button', grid === 'column' ? 'settings-button-active' : ''].join(' ')}>
          <Icon  size={25} icon="Columne-mesh" className={"icon-padding"}/> 
        </button>
      </div>

      {children({ grid })}
    </>
  )
}

export default Grid;