import React from "react"
import PropTypes from 'prop-types'
import ProductItem from '../components/items/product-item'
import useProducts from "../hooks/useProducts"
import useMedia from "../hooks/useMedia";

const AllProducts = ({ settings, grid }) => {
  const { objectLists } = useProducts(settings);
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  return (
    <div className={`post-wrapper post-wrapper-${grid} blackToGray`}>
      {[settings.types].flat().map((type) => {
        return objectLists[type].map(
          (item, i) => <ProductItem key={`${type}-${i}`} item={item} i={i + 1} settings={settings} grid={grid} isMobileResolution={isMobileResolution} />
        );
      })}
    </div>
  );
}

AllProducts.defaultProps = {
  grid: 'row',
}

AllProducts.propsTypes = {
  grid: PropTypes.oneOf(['row', 'column']),
  settings: PropTypes.object.isRequired
}

export default AllProducts